<template>
<v-app>
    <SingleAppBar :title="$t('settings.title')" :backward="true"/>
    <v-main class="natural lighten-4 fill-height">
      <v-container fluid class="pa-0">
         <v-list
      one-line
    >
          <v-subheader>
        {{$t('settings.personalData')}}
       </v-subheader>
     <v-list-item :to="{name: 'user'}">
 <v-list-item-avatar>
   <v-icon size="35">$vuetify.icons.profile</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  {{$t('settings.managePersonalData')}}
              </v-list-item-title>
          </v-list-item-content>
     </v-list-item>
     <v-divider/>
      <v-list-item :to="{name: 'selectsite'}">
      <v-list-item-avatar>
   <v-icon size="30">$vuetify.icons.site</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  {{ $t('settings.manageSite') }}
              </v-list-item-title>
          </v-list-item-content>
     </v-list-item>
    
     
    </v-list>

          <v-list
      one-line
    >
          <v-subheader>
            {{$t('settings.title')}}
       </v-subheader>
     <v-list-item>
 <v-list-item-avatar>
   <v-icon size="30">$vuetify.icons.noti</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                {{$t('settings.notification')}}
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action>
               <v-switch inset></v-switch>
           </v-list-item-action>
     </v-list-item>
     <v-divider/>
      <v-list-item :to="{name: 'lang'}">
      <v-list-item-avatar>
    <v-icon size="30">$vuetify.icons.lang</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  {{$t('settings.language')}}
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
            <!-- <v-radio-group  v-model="$i18n.locale">
                <v-radio row v-for="(lang, i) in langs" :key="`lang-${i}`" :label="lang.text" :value="lang.value" ></v-radio>
            </v-radio-group> -->
            <!-- <v-select solo dense v-model="$i18n.locale" :items="langs"/> -->
             {{ $i18n.locale }}
           </v-list-item-action>
     </v-list-item>
      <v-divider/>
       <v-list-item :to="{name: 'about'}">
         <v-list-item-avatar>
   <v-icon size="30">$vuetify.icons.info</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                {{$t('settings.about')}}
              </v-list-item-title>
          </v-list-item-content>
     </v-list-item>
      <v-divider/>
       <v-list-item >
         <v-list-item-avatar>
   <v-icon size="30">$vuetify.icons.policy</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                {{$t('settings.currentVersion')}}
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
              {{version}}
           </v-list-item-action>
     </v-list-item>
      <v-divider/>
       <v-list-item @click="confirmDialog=true">
         <v-list-item-avatar>
   <v-icon size="30" color="#212F4F">mdi-logout</v-icon>
 </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                {{$t('settings.logout')}}
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
       
           </v-list-item-action>
     </v-list-item>
     
    </v-list>
      </v-container>
      <v-dialog v-model="confirmDialog" persistent width="327">
<ConfirmCard :title="'คุณต้องการออกจากระบบหรือไม่'" @onClose="confirmDialog=false" @onConfirm="signOut"/>
      </v-dialog>
   
    </v-main>
</v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";
import ConfirmCard from "@/components/common/ConfirmCard"

export default {
  name: "Home",
  components: {
    SingleAppBar,
    ConfirmCard
  },
  methods: {
     signOut() {
      // this.$offlineStorage.set('isAuthen', false)
      // this.$router.push({name: 'signin'})
      this.$offlineStorage.set('isAuthen', false)
      localStorage.clear()
      this.$auth.logout()
    }
  },
  data() {
    return {
     version: process.env.VUE_APP_VERSION,
     confirmDialog: false,
     langs: [{text:'ภาษาไทย', value: 'th'}, {text:'English', value: 'en'}]
    }
  },
};
</script>

<style lang="css">
.meow {
  width: max-content;
}
.meow::v-deep input {
  width: 0;
}
</style>